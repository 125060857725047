/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import MyAccountQuery from 'Query/MyAccount.query';
import { MyAccountDispatcher as SourceDispatcher } from 'SourceStore/MyAccount/MyAccount.dispatcher';
import { clearShippingFormFields } from 'Store/Checkout/Checkout.action';
import {
    updateCustomerDetails,
    updateCustomerSignInStatus,
    updateIsLoading
} from 'Store/MyAccount/MyAccount.action';
import { showNotification } from 'Store/Notification/Notification.action';
import { updateActiveOfferDetails } from 'Store/Offer/Offer.action';
import { ORDERS } from 'Store/Order/Order.reducer';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import {
    deleteAuthorizationToken,
    getAuthorizationToken,
    setAuthorizationToken
} from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import { deleteGuestQuoteId, getGuestQuoteId, setGuestQuoteId } from 'Util/Cart';
import { removeUid } from 'Util/Compare';
import { prepareQuery } from 'Util/Query';
import { executePost, fetchMutation, getErrorMessage } from 'Util/Request';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);
export const CURRENT_CURRENCY = 'current_currency';

export const CUSTOMER = 'customer';

export const ONE_MONTH_IN_SECONDS = 2628000;

export const DELAY_RELOAD = 100;

/**
 * My account actions
 * @class MyAccount
 * @namespace Pwa/Store/MyAccount/Dispatcher */
export class MyAccountDispatcher extends SourceDispatcher {
    requestCustomerData(dispatch) {
        const query = MyAccountQuery.getCustomerQuery();

        const customer = BrowserDatabase.getItem(CUSTOMER) || {};

        if (customer.id) {
            dispatch(updateCustomerDetails(customer));
        }

        return executePost(prepareQuery([query])).then(
            /** @namespace Pwa/Store/MyAccount/Dispatcher/MyAccountDispatcher/requestCustomerData/executePost/then */
            ({ customer }) => {
                dispatch(updateCustomerDetails(customer));

                if (customer.offer) {
                    dispatch(updateActiveOfferDetails(customer.offer));
                }

                const { currency } = customer;
                BrowserDatabase.setItem(currency, CURRENT_CURRENCY, ONE_MONTH_IN_SECONDS);
                BrowserDatabase.setItem(customer, CUSTOMER, ONE_MONTH_IN_SECONDS);
            },
            /** @namespace Pwa/Store/MyAccount/Dispatcher/MyAccountDispatcher/requestCustomerData/executePost/then/dispatch/catch */
            (error) => dispatch(showNotification('error', getErrorMessage(error)))
        );
    }

    logout(authTokenExpired = false, dispatch) {
        if (authTokenExpired) {
            dispatch(showNotification('error', __('Your session is over, you are logged out!')));
            this.handleForceRedirectToLoginPage();
        } else {
            const mutation = MyAccountQuery.getRevokeAccountToken(getAuthorizationToken());
            fetchMutation(mutation);
            deleteAuthorizationToken();
            dispatch(showNotification('success', __('You are successfully logged out!')));
        }

        deleteGuestQuoteId();
        BrowserDatabase.deleteItem(ORDERS);
        BrowserDatabase.deleteItem(CUSTOMER);
        removeUid();

        dispatch(updateCustomerSignInStatus(false));
        dispatch(updateCustomerDetails({}));
        dispatch(clearShippingFormFields());

        // After logout cart, wishlist and compared product list is always empty.
        // There is no need to fetch it from the backend.
        CartDispatcher.then(
            ({ default: dispatcher }) => {
                dispatcher.resetGuestCart(dispatch);
                dispatcher.createGuestEmptyCart(dispatch);
            }
        );

        dispatch(updateCustomerDetails({}));
    }

    /**
     * Sign in action
     * @param {{email: String, password: String}} [options={}]
     * @memberof MyAccountDispatcher
     */
    async signIn(options = {}, dispatch) {
        const mutation = MyAccountQuery.getSignInMutation(options);

        const result = await fetchMutation(mutation);
        const { generateCustomerToken: { token } } = result;

        setAuthorizationToken(token);

        const cartDispatcher = (await CartDispatcher).default;
        const guestCartToken = getGuestQuoteId();
        // if customer is authorized, `createEmptyCart` mutation returns customer cart token
        const customerCartToken = await cartDispatcher.createGuestEmptyCart(dispatch);

        if (guestCartToken && guestCartToken !== customerCartToken) {
            // merge guest cart id and customer cart id using magento capabilities
            await cartDispatcher.mergeCarts(guestCartToken, customerCartToken, dispatch);
        }

        setGuestQuoteId(customerCartToken);
        cartDispatcher.updateInitialCartData(dispatch);

        await this.requestCustomerData(dispatch);

        dispatch(updateCustomerSignInStatus(true));
        dispatch(updateIsLoading(false));
        dispatch(hideActiveOverlay());
        dispatch(showNotification('success', __('You are successfully logged in!')));

        return true;
    }

    /**
     * Sign in as customer action
     * @memberof MyAccountDispatcher
     */
    async signInAsCustomer(dispatch) {
        const cartDispatcher = (await CartDispatcher).default;
        // if customer is authorized, `createEmptyCart` mutation returns customer cart token
        const customerCartToken = await cartDispatcher.createGuestEmptyCart(dispatch);

        setGuestQuoteId(customerCartToken);
        cartDispatcher.updateInitialCartData(dispatch);

        await this.requestCustomerData(dispatch);

        dispatch(updateCustomerSignInStatus(true));
        dispatch(updateIsLoading(false));
        dispatch(hideActiveOverlay());
        dispatch(showNotification('success', __('You are successfully logged in!')));

        return true;
    }

    createAccount(options = {}, dispatch) {
        const mutation = MyAccountQuery.getCreateAccountViaEmailMutation(options);
        dispatch(updateIsLoading(true));

        return fetchMutation(mutation).then(
            /** @namespace Pwa/Store/MyAccount/Dispatcher/MyAccountDispatcher/createAccount/then/finally/fetchMutation/then */
            (data) => {
                const {
                    consumeCustomerCreateForm
                } = data;

                return consumeCustomerCreateForm;
            },

            /** @namespace Pwa/Store/MyAccount/Dispatcher/MyAccountDispatcher/createAccount/then/finally/fetchMutation/then/catch */
            (error) => {
                dispatch(showNotification('error', getErrorMessage(error)));
                return false;
            }
        )
            .finally(
                /** @namespace Pwa/Store/MyAccount/Dispatcher/MyAccountDispatcher/createAccount/then/finally */
                () => {
                    dispatch(updateIsLoading(false));
                }
            );
    }
}

export default new MyAccountDispatcher();
